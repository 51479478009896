<template>
<div>
  <b-card
    title="基本信息">
      <b-row>
        <b-col cols="4">
          <form ref="saveAdminForm">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="账号"
              label-for="input-lg"
              :state="adminState.accountNameState"
              invalid-feedback="只能输入字母数字和_@"
            >
              <b-form-input
                id="input-1"
                v-model="admin.accountName"
                placeholder="请输入账号"
                required
                :state="adminState.accountNameState"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="当前用户角色"
              label-for="input-lg"
              v-if="admin.id"
            >
              <b-form-input
                id="input-1"
                v-model="admin.roleName"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label-size="lg"
                :label="admin.id ? '更新角色' : '所属角色'"
                label-for="input-lg"
              >
              <v-select
                placeholder="请选择角色"
                :options="roleOptions"
                :filterable="false"
                label="name"
                class="w-100"
                @input="roleHandleChange">
                  <li slot="list-footer" class="select-pagination">
                    <button :disabled="!hasRolePrevPage" @click.stop.prevent="pageRole(false,true)">上一页</button>
                    <button :disabled="!hasRoleNextPage" @click.stop.prevent="pageRole(true,false)">下一页</button>
                  </li>
              </v-select>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="用户名称"
              label-for="input-lg"
              :state="adminState.userNameState"
              invalid-feedback="必须填写用户名称"
            >
              <b-form-input
                id="input-1"
                v-model="admin.userName"
                placeholder="请输入用户名称"
                required
                :state="adminState.userNameState"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="电话号码"
              label-for="input-lg"
              :state="adminState.mobileState"
              invalid-feedback="必须填写电话号码"
            >
              <b-form-input
                id="input-1"
                v-model="admin.mobile"
                placeholder="请输入电话号码"
                required
                :state="adminState.mobileState"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="备注"
              label-for="input-lg"
            >
              <b-form-textarea
                id="textarea"
                v-model="admin.remark"
                placeholder="请输入备注"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="状态">
              <b-form-radio v-model="admin.enabled" name="禁用" value="false">禁用</b-form-radio>
              <b-form-radio v-model="admin.enabled" name="启用" value="true">启用</b-form-radio>
            </b-form-group>
          </form>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="handleAdminUpdateOk"
      v-if="$checkButtonPermission('202003001')"
      >
        保存
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="back"
      >
        取消
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea,BFormRadioGroup,BModal,BFormRadio
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BModal,
    BFormRadio,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      roleSearchCurrentPage: 1,
      roleSearchPageSize: 5,
      adminState:{
        accountNameState: null,
        userNameState: null,
        mobileState: null
      },
      admin:{
        id:"",
        accountName: "",
        userName: "",
        mobile: "",
        roleId: null,
        roleName: "",
        remark: "",
        enabled: true,
      },
      roleOptions: [
      ],
      roleFilter:null,
      selected: '0',
      options: [
        { item: '0', name: '启用' },
        { item: '1', name: '禁用' },
      ],
      roleTotal: 0,
    }
  },
  computed: {
    /**
     * 计算属性
     */
    hasRoleNextPage() {
      return (this.roleSearchCurrentPage * this.roleSearchPageSize) < this.roleTotal
    },
    hasRolePrevPage() {
      return this.roleSearchCurrentPage > 1
    },
  },
  created() {
    this.initAdminModal()
    if(this.$route.params && this.$route.params.id){
      console.log('params',this.$route.params)
      // 赋予初始值
      const {accountName,userName,roleId,mobile,remark,enabled,roleName,id} = this.$route.params
      this.admin = {accountName,userName,roleId,mobile,remark,enabled,roleName,id}
    }
  },
  methods:{
    back(){
      this.$router.back()
    },
    getAdminInfo(){

    },
    roleHandleChange(val){
      console.log('val',val)
      this.admin.roleId = val.id
    },
    handleAdminUpdateOk(bvModalEvent){
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // 调用保存的方法
      this.handleAdminSubmit()
    },
    /**
     * 用户表单提交
     */
    handleAdminSubmit(){
      this.adminState.accountNameState = this.admin.accountName && this.admin.accountName.length > 0 && this.admin.accountName.match("^[a-zA-Z0-9_@]+$")?true:false
      this.adminState.userNameState = this.admin.userName && this.admin.userName.length > 0?true:false
      this.adminState.mobileState = this.admin.mobile && this.admin.mobile.length > 0?true:false

      const valid = this.$refs.saveAdminForm.checkValidity()
      if(!valid)
        return
      console.log('this.admin',this.admin)
      let adminTemp = JSON.parse(JSON.stringify(this.admin))
      delete adminTemp.roleName
      request.post('tob/user/bAdmin/save', adminTemp).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
          this.adminState = {
            accountNameState: null,
            userNameState: null,
            mobileState: null
          }
        }
      })
    },
    initAdminModal(){
      // 分页获取角色列表
      this.roleSearchCurrentPage = 1
      this.roleSearchPageSize = 5
      this.pageRole()
    },
    pageRole(next,pre){
      if(next)
        this.roleSearchCurrentPage = this.roleSearchCurrentPage + 1
      if(pre)
        this.roleSearchCurrentPage = this.roleSearchCurrentPage - 1

      request.get('tob/user/bRole/page', { currentPage: this.roleSearchCurrentPage, pageSize: this.roleSearchPageSize }).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            this.roleOptions = res.data.data.data
            this.roleTotal = res.data.data.count
          }
        }
      })
    },
  },
  watch:{
  }
}
</script>
